import React from 'react'

import Seo from '../components/seo'
import { Bars } from  'react-loader-spinner'
import axios from 'axios';
import IconDone from '../../assets/done.svg'

class ContactIndex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            error_name: false,
            error_email: false,
            error_message: false,
            loading: false,
            sent: false,
        }
    }

    render() {
      return (
        <div>
          <Seo title="Contact"/>
          <div class="flex max-h-full pt-32 justify-items-center  ">
          <div class="flex justify-center m-auto w-full px-8">
                <form
                onSubmit={this.handleSubmit.bind(this)}
                class="w-full max-w-lg align-middle "
                method="post"
                >
                <div class="flex flex-wrap -mx-3 mb-3">
                    <div class="w-full md:w-full px-3 mb-0 ">
                    <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                    >
                        Name
                    </label>
                    <input
                        value={this.state.name}
                        onChange={this.onNameChange.bind(this)}
                        className={(this.state.error_name ? 'border-red-500' : 'border-gray-200 dark:border-gray-500' ) +" appearance-none block w-full bg-gray-200  dark:bg-secondaryDark text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:focus:border-gray-200"}
                        id="grid-last-name"
                        type="text"
                        placeholder="Name"
                    />
                    { this.state.error_name &&
                        <p class="text-red-500 text-xs italic">
                        Please fill out this field.
                        </p>
                    }
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-1">
                    <div class="w-full px-3">
                        <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-password"
                        >
                            E-mail
                        </label>
                        <input
                            onChange={this.onEmailChange.bind(this)}
                            value={this.state.email}
                            className={(this.state.error_email ? 'border-red-500' : 'border-gray-200 dark:border-gray-500' )}
                            id="email"
                            type="email"
                            placeholder="example@email.com"
                        />
                        {
                            this.state.error_email &&
                            <p v-if="error_email" class="text-red-500 text-xs italic">
                            Please fill out this field.
                            </p>
                        }
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                        <label
                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-password"
                        >
                            Message
                        </label>
                        <textarea
                            onChange={this.onMessageChange.bind(this)}
                            value={this.state.message}
                            className={(this.state.error_message ? 'border-red-500' : 'border-gray-200 dark:border-gray-500' ) + " no-resize appearance-none block w-full bg-gray-200  dark:bg-secondaryDark text-gray-700 dark:text-slate-100  border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 dark:focus:border-gray-200 h-48 resize-none"}
                            id="message"
                        ></textarea>
                        {
                            this.state.error_message &&
                            <p class="text-red-500 text-xs italic">
                                Please fill out this field.
                            </p>
                        }
                    </div>
                </div>
                <div class="flex justify-center">
                {
                !this.state.loading && <button
                type="submit"
                class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                >
                Send
                </button>
                }

                {(this.state.loading && !this.state.sent) && <Bars heigth="100" width="100" color="grey" ariaLabel="loading-indicator" />}
                {this.state.loading && this.state.sent && <IconDone/> }
                </div>
                </form>
                </div>
          </div>
        </div>
      )
    }


    onNameChange(event) {
        this.setState({name: event.target.value})
        if (this.state.name !== "") {
            this.setState({error_name: false})
        }
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
        if (this.state.email !== "") {
            this.setState({error_email: false})
        }
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
        if (this.state.message !== "") {
            this.setState({error_message: false})
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        var error = false;
        if (this.state.name  === ""){
            this.setState({error_name : true});
            error=true;
        }
        if (this.state.email === "") {
            this.setState({error_email : true});
            error=true;
        }
        if (this.state.message === "") {
            this.setState({error_message : true});
            error=true;
        }

        if(error) return;

        const email = {
            name : this.state.name,
            email : this.state.email,
            subject : "Portfolio Contact",
            text :  this.state.message
        }
        this.setState({loading : true});

        axios.post(`https://portfolio-email-zeta.vercel.app/`, email)
        .then(res => {
            this.setState({sent:true });
        });
    }
  }

export default ContactIndex;
